const { TextField, Autocomplete, Typography, Box, Stack, Divider } = require('@mui/material');
const { useState, useRef } = require('react');


//https://api.themoviedb.org/3/search/movie?query=${query}&include_adult=true&language=en-US&page=1

const apiKey = '15b28920762d1d8a9138c29dd1e8405e'

export default function AutocompleteInput(params) {

    let debounce;

    const { callback } = params

    const [options, setOptions] = useState([]);
    const [value, setValue] = useState()

    const getData = async (searchTerm) => {

        var listings = []
        
        //MOVIES
        await fetch(`https://api.themoviedb.org/3/search/movie?query=${searchTerm}&include_adult=true&language=en-US&page=1`, {
            headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNWIyODkyMDc2MmQxZDhhOTEzOGMyOWRkMWU4NDA1ZSIsInN1YiI6IjY1YmVkY2JlYTdlMzYzMDE4NDU0ODYwOCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.zz4UjQuTIHhER1-CtgocqAfWRYtygYfXEo8o9pWojk8'
            }
        })
            .then(function (response) {
            return response.json();
            })
            .then(function (myJson) {
                for (let result of myJson.results) {
                    listings.push(result)
                }
            });

        //TV

        await fetch(`https://api.themoviedb.org/3/search/tv?query=${searchTerm}&include_adult=true&language=en-US&page=1`, {
            headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNWIyODkyMDc2MmQxZDhhOTEzOGMyOWRkMWU4NDA1ZSIsInN1YiI6IjY1YmVkY2JlYTdlMzYzMDE4NDU0ODYwOCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.zz4UjQuTIHhER1-CtgocqAfWRYtygYfXEo8o9pWojk8'
            }
        })
            .then(function (response) {
            return response.json();
            })
            .then(function (myJson) {
                for (let result of myJson.results) {
                    listings.push(result)
                }
            });

        listings.sort((a, b) => {
            return b.popularity - a.popularity;
        });

        return listings

    };
    
    

    const onInputChange = (event, value, reason) => {
    if (value) {
        clearTimeout(debounce);
        debounce = setTimeout(async () => {
            console.log(value)
            setOptions(await getData(value));
        }, 500);

    } else {
        setOptions([]);
    }
    };

    return (
        <Autocomplete
        id="search-field"
        fullWidth
        options={options}
        value={value}
        onInputChange={onInputChange}
        onChange={(event, newValue) => {
            callback(newValue)
            setValue(newValue);
            const field = document.getElementById("search-field")
            field.focus()
            field.select()
          }}
        getOptionLabel={(option) => {
            var title = option.title ?? option.name
            var tvYear = year(option.first_air_date) ?? ""
            var movieYear = year(option.release_date) ?? ""
            
            return title + " " + tvYear + movieYear
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search" variant="outlined" />
        )}
        renderOption={(props, option, state, ownerState) => {
            return (
                <li {...props} style={{padding: 0, overflow: "hidden", position: 'relative'}}>
                    <Box>
                        <Stack direction="row" spacing={2} justifyContent="flex-start" padding={5}>
                            <Box sx={{
                                backgroundImage:`url(https://image.tmdb.org/t/p/original/${option.poster_path})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                minWidth: "150px",
                                height: "200px",
                            }}></Box>
                            <Stack direction="column" spacing={2} sx={{position: 'relative'}} justifyContent="center">
                                <Typography variant='h4'>{option.title ?? option.name}</Typography>
                                <Typography variant='h5'>{year(option.release_date ?? option.first_air_date)}</Typography>
                                <Typography>{option.overview.slice(0, 200)}...</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </li>
            )
        }}
      />
    )
}

function year(date) {
    var d = new Date(date ?? "")
    var y = d.getFullYear() ?? ''
    if (String(y) === "NaN") {y = ""}
    return y
}
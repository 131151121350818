import { Box, Chip, Divider, LinearProgress, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import getDomain from "./domain"
import { DataGridPro } from "@mui/x-data-grid-pro"



export default function SeedboxStatus() {

    const [freeSpace, setFreeSpace] = useState(0)
    const [driveCapacity, setDriveCapacity] = useState(0)

    const [rows, setRows] = useState([])

    const columns = [
        { field: 'id' },
        { field: 'name', headerName: "Listing Name", flex: 1 },
        {
            field: 'percentDone',
            headerName: "Progress",
            flex: 1,
            renderCell: (v) => { return <LinearProgress sx={{width: '100%'}} variant="determinate" value={v.value*100} />}
        },
        { field: 'downloadedEver', headerName: "Downloaded", flex: 1, renderCell: (v) => `${(v.value/1024/1024/1024).toFixed(2)} GB (${(v.row.percentDone*100).toFixed(2)}%)` },
        {
            field: 'sizeWhenDone',
            headerName: "Size",
            width: 120,
            renderCell: (v) => {
                const sizeGB = (v.value / 1073741824).toFixed(2)
                if (sizeGB > 50) { return <Chip label={sizeGB + " GB"} color='error'/> }
                if (sizeGB > 20) { return <Chip label={sizeGB + " GB"} color='warning'/> }
                if (sizeGB < 20.1) { return <Chip label={sizeGB + " GB"} color='success'/> }
                else { return <Chip label={sizeGB + " GB"} /> }
            }
        },
        { field: 'peersConnected', headerName: "Peers", width: 90},
    ]


    const getSpace = async () => {
        const res = await fetch(`${getDomain()}/storage`)
        const json = await res.json()

        const { free, size } = json

        setFreeSpace(free/1073741824)
        setDriveCapacity(size/1073741824)

    }

    const loop = async () => {
        await getActiveTorrents()
        await getSpace()
    }


    const getActiveTorrents = async () => {
        try {
            const res = await fetch(`${getDomain()}/status`)
            const json = await res.json()
            setRows(json.active.torrents)
            console.log("ACTIVE", json)
        } catch (error) {

        }
    }




    useEffect(() => {
        loop().catch((e) => console.log(e))
      const interval = setInterval(() => {
        loop().catch((e) => console.log(e))
      }, 2000)

      return () => clearInterval(interval)
    }, [])


    return (
        <Stack>
            
            <Typography variant="h4">Active Torrents</Typography>
            <Divider sx={{marginTop: 1}}/>
            <br></br>
            <DataGridPro 
                columns={columns}
                rows={rows}
                initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false,
                      },
                    },
                  }}
            />
            <br></br>
            <br></br>
            <Typography variant="h4">Storage</Typography>
            <Divider sx={{marginTop: 1}}/>
            <br></br>
            <LinearProgress sx={{height: '20px'}} variant="determinate" value={100-(freeSpace/driveCapacity)*100} />
            <Typography variant="p">{freeSpace.toFixed(2)} GB / {driveCapacity.toFixed(2)} GB | {(100-(freeSpace/driveCapacity)*100).toFixed(2)}% Used</Typography>
        </Stack>
    )
}




/*

null {
  removed: [],
  torrents: [
    {
      activityDate: 1707537232,
      addedDate: 1707535193,
      bandwidthPriority: 0,
      comment: '',
      corruptEver: 0,
      creator: '',
      dateCreated: 0,
      desiredAvailable: 12061179904,
      doneDate: 0,
      downloadDir: '/home/wingate/GitHub/plex-torrent-2024/plex/movies',
      downloadLimit: 100,
      downloadLimited: false,
      downloadedEver: 3500589534,
      error: 0,
      errorString: '',
      eta: 8756,
      fileStats: [Array],
      files: [Array],
      hashString: 'fc886fb350e444ee00fd18778f4f402e0c37cf91',
      haveUnchecked: 43581440,
      haveValid: 3453895620,
      honorsSessionLimits: true,
      id: 3,
      isFinished: false,
      isPrivate: false,
      leftUntilDone: 12061179904,
      magnetLink: 'magnet:?xt=urn:btih:fc886fb350e444ee00fd18778f4f402e0c37cf91&dn=Upgraded.2024.2160p.AMZN.WEB-DL.DDP5.1.Atmos.HDR.H.265-FLUX%5BTGx%5D&tr=http%3A%2F%2Fp4p.arenabg.com%3A1337%2Fannounce&tr=udp%3A%2F%2F47.ip-51-68-199.eu%3A6969%2Fannounce&tr=udp%3A%2F%2F9.rarbg.me%3A2780%2Fannounce&tr=udp%3A%2F%2F9.rarbg.to%3A2710%2Fannounce&tr=udp%3A%2F%2F9.rarbg.to%3A2730%2Fannounce&tr=udp%3A%2F%2F9.rarbg.to%3A2920%2Fannounce&tr=udp%3A%2F%2Fopen.stealth.si%3A80%2Fannounce&tr=udp%3A%2F%2Fopentracker.i2p.rocks%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.cyberia.is%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.dler.org%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.internetwarriors.net%3A1337%2Fannounce&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.openbittorrent.com%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=udp%3A%2F%2Ftracker.pirateparty.gr%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.tiny-vps.com%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.torrent.eu.org%3A451%2Fannounce',
      manualAnnounceTime: -1,
      maxConnectedPeers: 100,
      metadataPercentComplete: 1,
      name: 'Upgraded.2024.2160p.AMZN.WEB-DL.DDP5.1.Atmos.HDR.H.265-FLUX[TGx]',
      'peer-limit': 100,
      peers: [Array],
      peersConnected: 47,
      peersFrom: [Object],
      peersGettingFromUs: 9,
      peersKnown: 0,
      peersSendingToUs: 19,
      percentDone: 0.2247,
      pieceCount: 1855,
      pieceSize: 8388608,
      pieces: 'ghgOQCKLkgJwNhpma1fH4loqACLArEokiQb1pHKVHCyCbK4QdeAAuBEolZggqjSPAwMIwAwxENgQGhoAJQ6gEqgAAICEiCQClCQADoSmkAVAGYLCACEAEzeYFeYgIAASEEBEBAGgAQaEIAINAoAARAAADQAAAEABAAQQ4ABAAAkIQAIgAZqDACgEQACAAAQAKAAigFAAAQChYAAAQAAImUCAAAAQBIOAAAAgCgABCxSCGAgAqQgEIMCQREEIRRAwAAQIREAQEAgFEWCAAAQQwJEACwYAFggAAgAIiIAAACAgAQAlKJLBBg==',
      priorities: [Array],
      rateDownload: 1377000,
      rateUpload: 332000,
      recheckProgress: 0,
      seedIdleLimit: 30,
      seedIdleMode: 0,
      seedRatioLimit: 0,
      seedRatioMode: 0,
      sizeWhenDone: 15558656964,
      startDate: 1707535203,
      status: 4,
      torrentFile: '/var/lib/transmission-daemon/.config/transmission-daemon/torrents/fc886fb350e444ee00fd18778f4f402e0c37cf91.torrent',
      totalSize: 15558656964,
      trackerStats: [Array],
      trackers: [Array],
      uploadLimit: 100,
      uploadLimited: false,
      uploadRatio: 0.4268,
      uploadedEver: 1494115241,
      wanted: [Array],
      webseeds: [],
      webseedsSendingToUs: 0
    }
  ]
}

*/
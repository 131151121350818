import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import getDomain from "./domain";


export default function Magnet() {

    const [ magnet, setMagnet] = useState()

    const [type, setType] = useState('');

    const handleChange = (event) => {
        setType(event.target.value);
    };

    async function sendMagnet() {

        var response = await fetch(`${getDomain()}/add?category=${type}`, {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({magnet: magnet, category: type})
        })
    
        console.log(await response.json())
    }

    return (
        <Box>
            <FormControl sx={{minWidth: '200px'}}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={"movie"}>Movie</MenuItem>
                    <MenuItem value={"tv"}>TV</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                </Select>
            </FormControl>
            <br></br>
            <br></br>
            <FormControl sx={{width: '100%'}} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Magnet Submission</InputLabel>
                <OutlinedInput
                    id="magnet"
                    onChange={(e) => setMagnet(e.target.value)}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="submit"
                        onClick={(e) => sendMagnet()}
                        edge="end"
                        >
                        {<SendIcon />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Magnet Submission"
                />
            </FormControl>
        </Box>
    )
}



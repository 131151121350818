import { useEffect, useState } from 'react';
import { DataGridPro, GridActionsCellItem, GridKeyboardArrowRight, GridLogicOperator, GridToolbar } from '@mui/x-data-grid-pro';
import { Box, Divider, Typography } from '@mui/material';
import { Image } from '@mui/icons-material';


export default function Popular() {


    const [movie, setMovie] = useState([])
    const [tv, setTv] = useState([])

    const movieColumns = [
        { field: 'id', hide: true },
        {
            field: 'backdrop_path',
            headerName: "",
            renderCell: (v) => {
                let source = `https://image.tmdb.org/t/p/original/${v.value}`
                return (
                    <Box>
                        <img src={source} style={{objectFit: 'contain', width: '100%', height: '100%'}}></img>
                    </Box>
                )
            },
            flex: 1
        },
        { field: 'title', headerName: "Title", flex: 1,
            renderCell: (v) => {
                return(
                    <Typography variant='h4'>{v.value}</Typography>
                )
            }
        },
        {
            field: 'release_date',
            headerName: "Release",
        },
    ]
    const tvColumns = [
        { field: 'id', hide: true },
        {
            field: 'backdrop_path',
            headerName: "",
            renderCell: (v) => {
                let source = `https://image.tmdb.org/t/p/original/${v.value}`
                return (
                    <Box>
                        <img src={source} style={{objectFit: 'contain', width: '100%', height: '100%'}}></img>
                    </Box>
                )
            },
            flex: 1
        },
        { field: 'original_name', headerName: "Title", flex: 1,
            renderCell: (v) => {
                return(
                    <Typography variant='h4'>{v.value}</Typography>
                )
            }
        },
        {
            field: 'first_air_date',
            headerName: "Release",
        },
    ]

    const getResults = async (url, type) => {
    
        const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNWIyODkyMDc2MmQxZDhhOTEzOGMyOWRkMWU4NDA1ZSIsIm5iZiI6MTcyNDExNTkwOC4wMzgxMDYsInN1YiI6IjY1YmVkY2JlYTdlMzYzMDE4NDU0ODYwOCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.qSv2SC-vJ1NEBVU5yv0nkDczhNHeS6kJMoNBhSThlDQ'
        }
        };
    
        let res = await fetch(url, options)
        let results = await res.json()
        console.log(type, '\n', results)
        if (type === 'movie') {
            setMovie(results.results)
        } else {
            setTv(results.results)
        }
    }

    useEffect(() => {
        getResults('https://api.themoviedb.org/3/movie/popular?language=en-US&page=1', 'movie')
        getResults('https://api.themoviedb.org/3/tv/popular?language=en-US&page=1', 'tv')
    }, [])
    

    return (
        <Box>
            <br></br>
            <br></br>
            <br></br>
            <Typography variant='h2'>Popular Now</Typography>
            <Divider/>
            <br></br>
            <br></br>
            <Typography variant='h3' id="popMovies">Movies</Typography>
            <DataGridPro
                rows={movie}
                columns={movieColumns}
                rowHeight={150}
                columnVisibilityModel={{
                    id: false, // Hide the ID column
                  }}
            />
            <br></br>
            <Typography variant='h3' id="popTv">TV</Typography>
            <DataGridPro
                rows={tv}
                columns={tvColumns}
                rowHeight={150}
                columnVisibilityModel={{
                    id: false, // Hide the ID column
                  }}
            />
        </Box>
    )
}


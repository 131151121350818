import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Container, Slider, Stack, Typography} from '@mui/material'

import { LicenseInfo } from '@mui/x-license-pro';
import AutocompleteInput from './components/autocomplete';
import ResultsGrid from './components/results';
import Magnet from './components/magnet';
import getDomain from './components/domain';
import SeedboxStatus from './components/status';
import Popular from './components/popular';

LicenseInfo.setLicenseKey('37dc94c65fbb348da71d210a58a696a5Tz03Mjk0MSxFPTE3MjM5MTU3NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {

  const [selection, setSelection] = useState("")
  const [status, setStatus] = useState(
    <Stack direction="row" spacing={3} alignContent='center' justifyContent='left'>
      <CircularProgress size={20} />
      <Typography variant='p'>Checking Server Status</Typography>
    </Stack>
  )

  const [limit, setLimit] = useState(30)

  const handleSelect = (selection) => {
    console.log(selection)
    setSelection(selection)
  }

  const checkStatus = async () => {
      try {
        console.log("Checking Status")
        await fetch(`${getDomain()}/online`, { timeout: 1000 })
        console.log("Online")
        setStatus(<Alert severity="success">Service Online</Alert>)
      } catch (error) {
        offline(error)
      }


  }


  document.title = "Wingate Plex"

  const offline = (e) => {
        console.log('page down *****', e)

        var seconds = 10

        setInterval(() => {

          setStatus( <Alert severity="error">Server is Offline. Please notify <a href="mailto:gates@raptlex.com">gates@raptlex.com</a>. Retrying in {seconds} seconds.</Alert> )
          seconds--

          if (seconds < 0){ window.location.reload()}

        }, 1000)

        
   }



  useEffect( () => {
    checkStatus()
  }, [])


  
  return (
    <Box sx={{padding: '20px', width: '100vw', height: '100vh'}}>
      <title>Wingate Plex</title>
        <Container sx={{maxWidth:'100vw', overflow:'hidden'}}>
          <Stack spacing={5} alignContent="flex-start" justifyContent="flex-start">
            <br></br>
            <Typography variant='h2'>Wingate Plex Downloader</Typography>
            <Typography>Use this tool to search for and select the best Movie or TV show to download into Plex</Typography>
            {status}
            <br></br>
            <AutocompleteInput callback={handleSelect} />
            <Stack direction='row' spacing={2}>
              <Button variant='contained' href='#popMovies'>Popular Movies</Button>
              <Button variant='contained' href='#popTv'>Popular TV Shows</Button>
            </Stack>
            <ResultsGrid query={selection} limit={limit}/>
            <Typography>Or submit a magnet link manually:</Typography>
            <Magnet />
            <SeedboxStatus />
            <Popular/>
          </Stack>
        </Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
    </Box>
  );
}

export default App;

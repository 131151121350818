import React, { useEffect, useMemo } from 'react';
import { Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, Icon, Input, InputLabel, MenuItem, Modal, Select, Skeleton, Slider, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { DataGridPro, GridActionsCellItem, GridKeyboardArrowRight, GridLogicOperator, GridToolbar } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import getDomain from './domain';

//icons
import SendIcon from '@mui/icons-material/Send';
import FourKIcon from '@mui/icons-material/FourK';
import FourKPlusIcon from '@mui/icons-material/FourKPlus';
import FourKOutlinedIcon from '@mui/icons-material/FourKOutlined';
import FourKPlusOutlinedIcon from '@mui/icons-material/FourKPlusOutlined';
import HdIcon from '@mui/icons-material/Hd';
import HdOutlinedIcon from '@mui/icons-material/HdOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import StreamWindow from './stream';




export default function ResultsGrid(params) {

    const [rows, setRows] = useState([])
    const { query } = params ?? ""
    const [loadState, setLoadState] = useState(false)

    const [tvVisibility, setTvVisibility] = useState("none")
    const [showDetails, setShowDetails] = useState()
    const [season, setSeason] = React.useState('');
    const [episode, setEpisode] = React.useState('');

    var search = query?.name ?? query?.title ?? ""

    var id = query?.id
    search = search.replace(/[^a-zA-Z0-9 ]/g, "");

    const year = query?.release_date?.split("-")[0] ?? ""

    const TvControls = () => {
        if (!year) {

            console.log(showDetails )

            const handleSeasonChange = (event) => {
                setSeason(event.target.value);
              };
            const handleEpisodeChange = (event) => {
                setEpisode(event.target.value);
            };

            var episodeArray = []

            for (let s = 0; s < showDetails?.seasons[season]?.episode_count; s++) {
                episodeArray.push(<MenuItem value={s+1}>{s+1}</MenuItem>)
            }

            console.log(episodeArray)

            return (
                <Stack direction="row">
                    <FormControl>
                            <InputLabel id="season">Season</InputLabel>
                            <Select
                            labelId='season'
                            sx={{minWidth:'200px'}}
                            value={season}
                            width
                            label="Season"
                            onChange={handleSeasonChange}
                        >
                            {showDetails?.seasons.map((season, index) => {
                                return (
                                    <MenuItem value={index+1}>{index+1}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl>
                            <InputLabel id="episode">Episode</InputLabel>
                            <Select
                            labelId='episode'
                            sx={{minWidth:'200px'}}
                            value={episode}
                            label="Episode"
                            onChange={handleEpisodeChange}
                        >
                            {episodeArray}
                        </Select>
                    </FormControl>
                </Stack>
            )
        }
    }

    useEffect(() => {
        async function api(query, providers) {
            setLoadState(true)
            await fetch(`${getDomain()}/tg?query=${search} ${year}`)
            .then(async (res) => await res.json())
            .then((json) => setRows(json))

            await getShowDetails(id)
            .then((json) => setShowDetails(json))
    
            setLoadState(false)
        }
        if (query) { api() }
    }, [query?.name ?? query?.title ?? ""]
    )

    const columns = [
        { field: 'id' },
        { field: 'title', headerName: "Listing Name", flex: 1 },
        {
            field: 'resIcon',
            headerName: "",
            width: 50,
            valueGetter: (r) => r.row.metadata,
            renderCell: (v) => {
                const resolution = parseInt(v.value.resolution) ?? 0
                if (resolution > 2159) { return <FourKPlusIcon  fontSize="large" color='primary'/>}
                if (resolution > 1079) { return <HdIcon fontSize="large" color="secondary" /> }
                else { return "" }
            }

        },
        {
            field: 'seedIcon',
            headerName: "Speed",
            width: 60,
            valueGetter: (r) => parseInt(r.row.seeds),
            renderCell: (v) => {
                if (v.value > 100) { return <CircleIcon color='success' />}
                if (v.value > 30) { return <CircleIcon color='warning'/>}
                if (v.value < 31) { return <CircleIcon  color='error'/>}
                else { return "" }
            }

        },
        { field: 'resolution',
        headerName: "Res",
        type: 'number',
        valueGetter: (r) => parseInt(r.row.metadata.resolution),
        valueFormatter: (v) => `${v.value}p`
    },
        {
            field: 'size',
            headerName: "Size",
            width: 120,
            renderCell: (v) => {
                const sizeGB = (v.value / 1073741824).toFixed(2)
                if (sizeGB > 50) { return <Chip label={sizeGB + " GB"} color='error'/> }
                if (sizeGB > 20) { return <Chip label={sizeGB + " GB"} color='warning'/> }
                if (sizeGB < 20.1) { return <Chip label={sizeGB + " GB"} color='success'/> }
                else { return <Chip label={sizeGB + " GB"} /> }
            }
        },
        { field: 'seeds', headerName: "Seeds", width: 90},
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              //<StreamWindow params />,
              <GridActionsCellItem
                icon={<SendIcon />}
                label="Send to Plex"
                onClick={(event) => sendTorrent(params, query)}
              />,
            ],
          },
    ]

    if (search) {
        return (
            <Box>
                {/*c}
                <TvControls sx={{display:tvVisibility}}/>
                {c*/}
                <br></br>
                <DataGridPro 
                    columns={columns}
                    rows={rows}
                    initialState={{
                        columns: {
                          columnVisibilityModel: {
                            // Hide columns status and traderName, the other columns will remain visible
                            id: false,
                          },
                        },
                        sorting: {
                            sortModel: [
                                { id: 1, field: 'resolution', sort: 'desc' },
                                { id: 2, field: 'seeds', sort: 'desc' }
                            ],
                        },
                      }}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: GridToolbar,
                      }}
                    loading={loadState}
                />
            </Box>
        )
    } else {
        return ""
    }
}


async function sendTorrent(params, query) {
    const { metadata, trackerId } = params.row

    let category = "movies"
    if (query.name) {category = "tv"}
    console.log(`Category is ${category}`)

    var response = await fetch(`${getDomain()}/add?category=${category}`, {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify({magnet: trackerId, category: category})
    })

    console.log(await response.json())
}

async function getShowDetails(showId) {
    const url = `https://api.themoviedb.org/3/tv/${showId}?languseason=en-US`;
            const options = {
            method: 'GET',
            headers: {
                accept: 'application/json',
                Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNWIyODkyMDc2MmQxZDhhOTEzOGMyOWRkMWU4NDA1ZSIsIm5iZiI6MTcyMzE2NTUwNi44NTg1MDgsInN1YiI6IjY1YmVkY2JlYTdlMzYzMDE4NDU0ODYwOCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.Zb7y-60lB-M4flBFCkQa0dKLx5N12YTCaPApbqyO_LA'
            }
            };

            const res = await fetch(url, options)
            const json = await res.json()

            return json
}